import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Footer from '../components/footer';
import './Home.css'; // Подключаем стили
import homeBg from '../assets/home-bg.0efcd27.png';
import MobNavbar from '../components/MobNav';
import LoginModal from '../components/LoginModal'; // Модальное окно для логина

const Home = () => {
  const [newGames, setNewGames] = useState([]); // Устанавливаем начальное значение как пустой массив
  
  const navigate = useNavigate(); // Хук для навигации
  const [cart, setCart] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Для отслеживания состояния входа
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [productCount, setProductCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleLogin = () => {
    setIsLoginModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsLoginModalOpen(false);
  };
  const handleUserLogin = (userData, token) => {
    setIsLoggedIn(true);
    setUser(userData);
    localStorage.setItem('token', token);
  };


  useEffect(() => {
  const fetchProductCount = async () => {
    try {
      const response = await axios.get('https://www.kerambit.me/api/user/products/count'); // Локальный вызов API
      setProductCount(response.data.count);
    } catch (error) {
      console.error('Error fetching product count:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchProductCount();
}, []);

  useEffect(() => {
    const fetchNewGames = async () => {
      try {
        const response = await axios.get('https://www.kerambit.me/api/products/new');
        console.log('Data from API:', response.data); // Логируйте данные
        if (Array.isArray(response.data)) {
          setNewGames(response.data); // Убедитесь, что это массив
        } else {
          console.error('Received data is not an array:', response.data);
        }
      } catch (error) {
        console.error('Error fetching new games:', error);
      }
    };
  

    fetchNewGames();
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }

  }, []);

  const handleCardClick = (productId) => {
    navigate(`/product/${productId}`);
  };
  
  const handleAddToCart = async (product) => {
    try {
      const token = localStorage.getItem('token');
      let updatedCart = [...cart];
  
      // Проверка, есть ли продукт уже в корзине
      const existingProductIndex = updatedCart.findIndex((item) => item._id === product._id);
  
      if (existingProductIndex !== -1) {
        // Если продукт уже в корзине, увеличиваем его количество
        updatedCart[existingProductIndex].quantity += 1;
      } else {
        // Если продукта нет, добавляем его с количеством 1
        updatedCart.push({ ...product, quantity: 1 });
      }
  
      if (token) {
        // Если пользователь авторизован, добавляем товар в корзину через API
        await axios.post(
          'https://www.kerambit.me/api/cart/add',
          { productId: product._id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
  
      // Локальное обновление состояния корзины
      setCart(updatedCart);
      localStorage.setItem('cart', JSON.stringify(updatedCart)); // Сохранение в локальном хранилище
  
      // Отправляем кастомное событие для обновления других компонентов
      const event = new Event('cartUpdate');
      window.dispatchEvent(event);
  
      console.log('Product added to cart successfully');
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };
  
  const handleIncreaseQuantity = (productId) => {
    const updatedCart = cart.map((item) => {
      if (item._id === productId) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
  
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  
    // Отправляем кастомное событие для обновления других компонентов
    const event = new Event('cartUpdate');
    window.dispatchEvent(event);
  };
  
  const handleDecreaseQuantity = (productId) => {
    const updatedCart = cart.map((item) => {
      if (item._id === productId && item.quantity > 1) {
        return { ...item, quantity: item.quantity - 1 };
      }
      return item;
    });
  
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  
    // Отправляем кастомное событие для обновления других компонентов
    const event = new Event('cartUpdate');
    window.dispatchEvent(event);
  };
  
  const getOverlayStylesByRarity = (rarity) => {
    switch (rarity) {
      case 'Covert':
        return {
          background: 'linear-gradient(180deg, #ee2c2c, rgba(238, 44, 44, .1))',
          borderColor: '#ee2c2c'
        };
      case 'Classified':
        return {
          background: 'linear-gradient(180deg, #b261fc, rgba(178, 97, 252, .1))',
          borderColor: '#b261fc'
        };
      case 'Restricted':
        return {
          background: 'linear-gradient(180deg, #fd33c7, rgba(253, 51, 199, .1))',
          borderColor: '#fd33c7'
        };
      case 'Knife':
        return {
          background: 'rgb(255, 224, 51)',
          borderColor: '#ffe033'
        };
      case 'Mil-Spec Grade':
        return {
          background: 'linear-gradient(180deg, #476cff, rgba(71, 108, 255, .1))',
          borderColor: '#476cff'
        };
      case 'Industrial Grade':
        return {
          background: 'linear-gradient(180deg, #33c2fa, rgba(51, 194, 250, .1))',
          borderColor: '#33c2fa'
        };
      case 'Consumer Grade':
        return {
          background: '#150e27',
          borderColor: '#150e27'
        };
      default:
        return {
          background: '#150e27',
          borderColor: '#150e27'
        };
    }
  };
  const [faqOpen, setFaqOpen] = useState([false, false, false, false]);

  const toggleFaq = (index) => {
    const newFaqOpen = [...faqOpen];
    newFaqOpen[index] = !newFaqOpen[index];
    setFaqOpen(newFaqOpen);
  };
  const handleSeeAllSkins = () => {
    navigate('/game'); // Навигация на страницу /game
  };

  return (
    <div className={`home `}>
      <MobNavbar/>

      <div className="first-section" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* Левая картинка */}
        <div className="side-image left">
          <img
            src={require('../assets/home-img.01a4610-1.png')}
            alt="Home left"
            className="foreground-image"
          />
        </div>

        {/* Центральное содержимое */}
        <div className="center-content" style={{ textAlign: 'center', flex: 1 }}>
          <h1 className="section-title">A seamless and smarter way to buy CS2 skins</h1>
          <p className="section-subtitle">
            Acquire skins faster and safer, with industry-leading security for every transaction
          </p>
          <div className="buttons-container">
            <button className="primary-button" onClick={handleSeeAllSkins}>
              Shop
            </button>
          </div>
        </div>

        {/* Правая картинка */}
        <div className="side-image right">
          <img
            src={require('../assets/home-img.01a4610.png')}
            alt="Home right"
            className="foreground-image"
          />
        </div>
      </div>

      <div className="second-section">
        <div className="stats-grid">
          <div className="stat-card">
            <h2 className="stat-number">19+</h2>
            <hr className="stat-divider" />
            <p className="stat-description">Seconds from login to checkout</p>
          </div>
          <div className="stat-card">
            <h2 className="stat-number">
              3089+
            </h2>
            <hr className="stat-divider" />
            <p className="stat-description">exclusive CS2 items avaliable now</p>
          </div>
          <div className="stat-card">
            <h2 className="stat-number">3k+</h2>
            <hr className="stat-divider" />
            <p className="stat-description">Completed transactions</p>
          </div>
          <div className="stat-card">
            <h2 className="stat-number">24/7</h2>
            <hr className="stat-divider" />
            <p className="stat-description">Quick and reliable tech support, responding in less than 5 minutes</p>
          </div>
        </div>
      </div>

      <div className="products-home-section">
        <div className="products-wrapper">
          <h1 className="product-title">Popular Skins</h1>
          <div className="products-grid">
            {newGames.length > 0 ? (
              newGames.map((product) => {
                const cartItem = cart.find((item) => item._id === product._id); // Проверяем, есть ли продукт в корзине
                
                return (
                  <div className="product-cart" key={product._id} data-rarity={product.Rarity}>
                    <div className="product-cart_inside">
                      <span className="product-game-quality-tag">{product.wear}</span>
                      <div className="product-image-container" onClick={() => handleCardClick(product._id)}>
                        <img
                          src={
                            product.imageUrl.startsWith('/images/') 
                              ? product.imageUrl  // Если путь начинается с /images/, используем его как есть
                              : require(`../assets/${product.imageUrl}`) // Для старых данных используем require
                          }
                          alt={product.name}
                          className="product-game-image"
                        />
                      </div>
                      <div className="product-game-info">
                        <div className="product-game-name-category">
                          <span className="product-game-category">
                            {product.category} • {product.name}
                          </span>
                          <h4 className="product-name">{product.description}</h4>
                        </div>
                        <div className="product-game-price-quality">
                          <p className="product-game-price">{product.price} €</p>
                        </div>
                      </div>
                      <div className="product-game-footer">
                        {cartItem ? (
                          <div className="quantity-controls">
                            <button onClick={() => handleDecreaseQuantity(product._id)}>-</button>
                            <span>{cartItem.quantity}</span>
                            <button onClick={() => handleIncreaseQuantity(product._id)}>+</button>
                          </div>
                        ) : (
                          <button className="buy-game-now-button" onClick={() => handleAddToCart(product)}>
                            <img src={require('../assets/shop.png')} alt="Cart" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>Loading new games...</p>
            )}
          </div>
        </div>
      </div>

      <div className="faq-section">
        <h2 className="faq-title">FAQ</h2>
        <div className="faq-container">
          <div className="faq-item">
            <button className="faq-question" onClick={() => toggleFaq(0)}>
              How regularly do you add new skins?
              <span className="faq-toggle">{faqOpen[0] ? '×' : '+'}</span>
            </button>
            {faqOpen[0] && (
              <div className="faq-answer">
                We keep our inventory up to date, adding fresh skins as soon as they’re listed for sale.
              </div>
            )}
          </div>
          <div className="faq-item">
            <button className="faq-question" onClick={() => toggleFaq(1)}>
              Which CS2 skins are available for purchase?
              <span className="faq-toggle">{faqOpen[1] ? '×' : '+'}</span>
            </button>
            {faqOpen[1] && (
              <div className="faq-answer">
                We support all types of CS2 skins, including rifles, pistols, knives, and more.
              </div>
            )}
          </div>
          <div className="faq-item">
            <button className="faq-question" onClick={() => toggleFaq(2)}>
              How does it work?
              <span className="faq-toggle">{faqOpen[2] ? '×' : '+'}</span>
            </button>
            {faqOpen[2] && (
              <div className="faq-answer">
               It's very simple! During registration, you link your Steam Trade Link to your profile. After that, you can purchase the skin you like, and it will appear in your Steam inventory shortly. Skins are automatically transferred through our automated system. After the purchase, you will see your item in your profile, and by clicking the "Receive" button, the transfer process will start through the trade link you provided, within 10 minutes. If the transfer attempt fails or something goes wrong, you can try to receive the item again or contact our support team.
              </div>
            )}
          </div>
          <div className="faq-item">
            <button className="faq-question" onClick={() => toggleFaq(3)}>
              How can I get support?
              <span className="faq-toggle">{faqOpen[3] ? '×' : '+'}</span>
            </button>
            {faqOpen[3] && (
              <div className="faq-answer">
                You can contact our support team 24/7 through the "Contact Us" page.
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer/>
      <LoginModal isOpen={isLoginModalOpen} onClose={handleCloseModal} onUserLogin={handleUserLogin} />
    </div>
  );
};

export default Home;
